/**
 * Assets
 */
export const assetsHost = 'assets.noah.com';
const assetsBase = `https://${assetsHost}`;
export const assetPaths = {
  fonts: `${assetsBase}/fonts`,
  host: assetsHost,
  images: `${assetsBase}/images`,
} as const;

/**
 * Auth
 */
export const authPaths = {
  base: { path: '/auth' },
  error: { path: '/auth/error' },
  recovery: { path: '/auth/recovery', title: 'Forgotten Password' },
  signedOut: { path: '/auth/signedout', title: 'Signed Out' },
  signIn: { path: '/auth/signin', title: 'Login' },
  signOut: { path: '/auth/signout', title: 'Signing Off' },
  signUp: { path: '/auth/signup', title: 'Sign up' },
  verify: { path: '/auth/verify', title: 'Verify your email' },
} as const;

import type { BoxProps } from '@mui/material';
import { Box, CircularProgress } from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';

export function CircularSpinner({ sx }: Pick<BoxProps, 'sx'>): React.ReactElement {
  return (
    <Box sx={{ ...sx, position: 'relative' }}>
      <CircularProgress
        size={32}
        sx={{
          color: (theme) => theme.palette.grayscale[300],
        }}
        thickness={4}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        disableShrink
        color="secondary"
        size={32}
        sx={{
          animationDuration: '550ms',
          color: (theme) => theme.palette.grayscale[500],
          left: 0,
          position: 'absolute',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        thickness={4}
        variant="indeterminate"
      />
    </Box>
  );
}

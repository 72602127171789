import type React from 'react';
import type { TypographyVariantsOptions } from '@mui/material';
import { coreThemeConstants } from './coreThemeConstants';
import type { Tokens } from './designTokens';
import { convertTypographyStyleFromFigma, flattenValues } from './designTokens';
import type { TpFigmaTypography, TpTypography } from './types';

const headingBase: React.CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 500,
  letterSpacing: '-0.02em',
};

const buttonBase: React.CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 500,
  letterSpacing: '-0.02em',
  lineHeight: 1.2,
  textTransform: 'none',
};

// Use the concrete TpTypography type, which does not get augemented, so that we can be sure to include our TpCoreTypographyVariants whilst still allowing MUI overrides
// Also, using the concrete type means that it can't be augmented by other packages that export it, causing TS errors in this package

export const typography: TpTypography & TypographyVariantsOptions = {
  button: {
    // Apply buttonL styles to the default Mui button
    ...buttonBase,
    fontSize: '1rem',
  },
  buttonL: {
    ...buttonBase,
    fontSize: '1rem',
  },
  buttonM: {
    ...buttonBase,
    fontSize: '0.875rem',
  },
  buttonS: {
    ...buttonBase,
    fontSize: '0.875rem',
  },
  fontFamily: coreThemeConstants.fontFamily.standard,
  h5: {
    ...headingBase,
    fontSize: '2.125rem',
    lineHeight: 1.1176,
  },
};

export function generateTypography(tokens: (typeof Tokens)['Mobile']): TpTypography {
  const figmaTypography = flattenValues(tokens) as Record<string, TpFigmaTypography>;
  const result = {
    headingL: convertTypographyStyleFromFigma(figmaTypography['Heading L']),
    headingXL: convertTypographyStyleFromFigma(figmaTypography['Heading XL']),
    paragraphBodyL: convertTypographyStyleFromFigma(figmaTypography['Paragraph body L']),
    paragraphBodyLBold: convertTypographyStyleFromFigma(figmaTypography['Paragraph body L bold']),
    paragraphBodyM: convertTypographyStyleFromFigma(figmaTypography['Paragraph body M']),
    paragraphBodyMBold: convertTypographyStyleFromFigma(figmaTypography['Paragraph body M bold']),
    paragraphBodyS: convertTypographyStyleFromFigma(figmaTypography['Paragraph body S']),
    paragraphBodySBold: convertTypographyStyleFromFigma(figmaTypography['Paragraph body S bold']),
    subHeadingL: convertTypographyStyleFromFigma(figmaTypography['Subheading L']),
    subHeadingM: convertTypographyStyleFromFigma(figmaTypography['Subheading M']),
    subHeadingS: convertTypographyStyleFromFigma(figmaTypography['Subheading S']),
  };

  return { ...typography, ...result };
}

import type { ReactElement } from 'react';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

export function DotBadge(props: BoxProps): ReactElement {
  const theme = useTheme();

  const StyledDotBadge = styled(Box)<BoxProps>(() => ({
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.grayscale.white,
    borderRadius: '100%',
    borderStyle: 'solid',
    borderWidth: theme.spacing(0.28),
    display: 'block',
    height: theme.spacing(1.75),
    width: theme.spacing(1.75),
  }));

  return <StyledDotBadge component="span" {...props} />;
}

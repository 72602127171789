import type { HTMLAttributeAnchorTarget } from 'react';
import type { LoadingButtonProps } from '@mui/lab';
import { LoadingButton } from '@mui/lab';

export function PrimaryButton(
  props: LoadingButtonProps & { target?: HTMLAttributeAnchorTarget | undefined },
): React.ReactElement {
  return (
    <LoadingButton fullWidth color="primaryDark" size="large" variant="contained" {...props} />
  );
}

import type { ReactElement } from 'react';
import { MenuItem, TextField, Typography } from '@mui/material';
import type { TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChevronDownIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronDownIcon';

export type PpSelect = {
  menuTitle?: string;
  options: Array<string>;
} & TextFieldProps;

const StyledMenuTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

export function Select({ menuTitle, options, ...rest }: PpSelect): ReactElement {
  return (
    <TextField
      select
      {...rest}
      SelectProps={{
        IconComponent: ChevronDownIcon,
        MenuProps: {
          MenuListProps: {
            disablePadding: true,
          },
          PaperProps: {
            sx: {
              border: (theme) => `1px solid ${theme.palette.grayscale[300]}`,
              marginTop: 0.5,
            },
          },
        },
      }}
    >
      {menuTitle && <StyledMenuTitle variant="paragraphBodySBold">{menuTitle}</StyledMenuTitle>}
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}

import { Grid } from '@mui/material';
import type { GridProps } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';

export type PpFormGrid = PpWC & {
  spacing?: GridProps['spacing'];
  sx?: GridProps['sx'];
};

export function FormGrid({ children, spacing = 2.5, sx }: PpFormGrid): React.ReactElement {
  return (
    <Grid container spacing={spacing} sx={sx}>
      {children}
    </Grid>
  );
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function randomSleep(max = 500): Promise<void> {
  const randomTime = Math.floor(Math.random() * Math.floor(max));
  return sleep(randomTime);
}

export function trueKeysFromBooleanMap(map: Record<string, boolean>): string[] {
  const filtered = Object.entries(map).filter(([_, value]) => Boolean(value));

  return Array.from(filtered, (element) => element[0]);
}

export function isUndefinedOrNull(value: unknown): value is null | undefined {
  return value == null;
}

export function removeNullFields<T extends object>(obj: T): Partial<T> {
  if (typeof obj !== 'object') {
    return obj;
  }
  const newObj = { ...obj };
  Object.entries(newObj).forEach(([key, value]) => {
    if (value === null) {
      delete newObj[key as keyof typeof newObj];
    }
    if (typeof value === 'object') {
      removeNullFields(value);
    }
  });
  return newObj;
}

export type TpTimeout = ReturnType<typeof setTimeout>;
export async function withTimeout<T>(promise: T | Promise<T>, ms: number): Promise<T> {
  let timer: TpTimeout | null = null;

  // This promise will reject after the given timeout
  const timeoutPromise = new Promise<T>((_, reject) => {
    timer = setTimeout(() => {
      reject(new Error('Promise timed out'));
    }, ms);
  });

  try {
    // Wait for either the input promise or the timeout promise to settle
    const result = await Promise.race([promise, timeoutPromise]);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearTimeout(timer!);
    return result;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clearTimeout(timer!);
    throw error;
  }
}

import { Stack } from '@mui/material';
import type { StackProps } from '@mui/system';

export function TextWithIcon({
  children,
  sx,
}: Pick<StackProps, 'children' | 'sx'>): React.ReactElement {
  return (
    <Stack alignItems="center" direction="row" spacing={0.5} sx={sx}>
      {children}
    </Stack>
  );
}

import type { ReactElement } from 'react';
import { useMemo } from 'react';
import type { CtxRouter, PpWC } from '@noah-labs/fe-shared-ui-shared';
import { RouterContext } from '@noah-labs/fe-shared-ui-shared';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useOnRouteChange } from '../hooks/useOnRouteChange';

export function RouterProvider({ children }: PpWC): ReactElement {
  const history = useHistory();
  const location = useLocation();

  const routerValue = useMemo<CtxRouter>(
    () => ({
      goBack: () => history.goBack(),
      goForward: () => history.goForward(),
      location,
      pathname: location.pathname,
      push: (to) => history.push(to),
      replace: (to) => history.replace(to),
      search: location.search,
      useOnRouteChange,
      useParams,
    }),
    [history, location],
  );

  return <RouterContext.Provider value={routerValue}>{children}</RouterContext.Provider>;
}

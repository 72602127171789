import europeanUnion from '@noah-labs/fe-shared-ui-assets/svg/icons/european-union.svg';
import unitedKingdom from '@noah-labs/fe-shared-ui-assets/svg/icons/united-kingdom.svg';
import unitedStates from '@noah-labs/fe-shared-ui-assets/svg/icons/united-states.svg';
import { fiatCurrencies } from '@noah-labs/shared-currencies';
import { FiatCurrencyCode } from '@noah-labs/shared-schema-gql';
import type { FiatAmount } from '@noah-labs/shared-schema-gql';
import type { TpExtraFiatCurrencyUI, TpFiatCurrencyUI } from './types';

const extraFiatCurrencyUi: Record<FiatCurrencyCode, TpExtraFiatCurrencyUI> = {
  AED: { svg: null },
  ARS: { svg: null },
  AUD: { svg: null },
  BDT: { svg: null },
  BHD: { svg: null },
  BMD: { svg: null },
  BRL: { svg: null },
  CAD: { svg: null },
  CHF: { svg: null },
  CLP: { svg: null },
  CNY: { svg: null },
  COP: { svg: null },
  CZK: { svg: null },
  DKK: { svg: null },
  EUR: { svg: europeanUnion },
  GBP: { svg: unitedKingdom },
  HKD: { svg: null },
  HUF: { svg: null },
  IDR: { svg: null },
  ILS: { svg: null },
  INR: { svg: null },
  JPY: { svg: null },
  KRW: { svg: null },
  KWD: { svg: null },
  LKR: { svg: null },
  MMK: { svg: null },
  MXN: { svg: null },
  MYR: { svg: null },
  NGN: { svg: null },
  NOK: { svg: null },
  NZD: { svg: null },
  PHP: { svg: null },
  PKR: { svg: null },
  PLN: { svg: null },
  RUB: { svg: null },
  SAR: { svg: null },
  SEK: { svg: null },
  SGD: { svg: null },
  THB: { svg: null },
  TRY: { svg: null },
  TWD: { svg: null },
  UAH: { svg: null },
  USD: { svg: unitedStates },
  VEF: { svg: null },
  VND: { svg: null },
  ZAR: { svg: null },
};

export const fiatCurrenciesUi = Object.entries(fiatCurrencies).reduce(
  (acc, [key, value]) => {
    const valueWithIcons: TpFiatCurrencyUI = {
      ...value,
      ...extraFiatCurrencyUi[value.code],
    };
    acc[key as FiatCurrencyCode] = valueWithIcons;
    return acc;
  },
  {} as Record<FiatCurrencyCode, TpFiatCurrencyUI>,
);

export function fiatCurrencyFromString(code: string | undefined): TpFiatCurrencyUI | undefined {
  if (typeof code !== 'string') {
    return undefined;
  }
  const codeUpper = code.toUpperCase();
  // Happy to use 'as' here because it will return undefined if it doesn't exist
  return fiatCurrenciesUi[codeUpper as FiatCurrencyCode];
}

export function fiatCurrencyFromCode(code: FiatCurrencyCode): TpFiatCurrencyUI {
  return fiatCurrenciesUi[code];
}

/**
 * Returns the fiat amount for the given currency code, or the first one if it doesn't exist
 */
export function fiatAmountForCurrency(
  amounts: FiatAmount[],
  currencyCode: FiatCurrencyCode | undefined,
): FiatAmount {
  const amount = amounts.find((am) => am.FiatCurrency === currencyCode);
  return amount || amounts[0];
}

// TODO (cs): should really be coming from the 'master' countries / currencies list
export const fiatCurrencyItems = [
  {
    label: 'US Dollar',
    secondaryLabel: `${FiatCurrencyCode.USD} ($)`,
    svg: unitedStates,
    value: FiatCurrencyCode.USD,
  },
  {
    label: 'Great British Pound',
    secondaryLabel: `${FiatCurrencyCode.GBP} (£)`,
    svg: unitedKingdom,
    value: FiatCurrencyCode.GBP,
  },
  {
    label: 'Euro',
    secondaryLabel: `${FiatCurrencyCode.EUR} (€)`,
    svg: europeanUnion,
    value: FiatCurrencyCode.EUR,
  },
];

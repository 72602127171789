import { createSpacing } from '@mui/system';

export const coreThemeConstants = {
  elevation: {
    cta: {
      blue: '0px -5px 15px rgba(92, 66, 255, 0.25)',
      brown: '0px -5px 15px rgba(112, 85, 68, 0.25)',
      pink: '0px -5px 15px rgba(104, 37, 104, 0.25)',
      white: '0px 10px 40px 0px rgba(29, 29, 29, 0.25)',
    },
    l: '0px 31px 80px rgba(0, 0, 0, 0.12)',
    m: '0px 24px 80px rgba(0, 0, 0, 0.06)',
    s: '0px 10px 40px rgba(0, 0, 0, 0.05)',
  },
  fontFamily: {
    expanded: ['"GT America Expanded"', 'Verdana', 'monospace'].join(', '),
    extended: ['"GT America Extended"', 'Verdana', 'monospace'].join(', '),
    standard: ['"GT America Standard"', 'Verdana', 'monospace'].join(', '),
  },
  sidebarColumns: 2,
  spacing: createSpacing(8), // 8px grid
};

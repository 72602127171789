import { Box, Container } from '@mui/material';
import type { BoxProps } from '@mui/system';

export type PpSceneHeader = Pick<BoxProps, 'children' | 'textAlign'> & {
  dense?: boolean;
};

export function SceneHeader({
  children,
  dense = false,
  textAlign,
}: PpSceneHeader): React.ReactElement {
  // we use padding to ensure any background colours are 'full bleed'
  const pt = dense ? 2 : 4;
  return (
    <Box component="header" sx={{ pt, textAlign }}>
      <Container maxWidth="sm">{children}</Container>
    </Box>
  );
}

import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MuiSvgIcon } from '../icons/MuiSvgIcon';

export const Avatar = styled(Box)<BoxProps>(() => ({
  height: 'fit-content',
  position: 'relative',
  width: 'fit-content',
}));

type PpAvatarIcon = BoxProps & { size?: number };
export const AvatarIcon = styled(Box)<PpAvatarIcon>(({ size = 4, theme }) => ({
  '& svg': {
    display: 'block',
    height: '100%',
    width: '100%',
  },
  fontSize: theme.spacing(size),
  height: '1em',
  lineHeight: '1em',
  width: '1em',
}));

export const AvatarBadge = styled(Box)<BoxProps>(() => ({
  bottom: 0,
  position: 'absolute',
  right: 0,
}));

type PpCurrencyAvatar = PpAvatarIcon & {
  svg?: TpReactSvg | null;
};
export function AvatarIconFromSvg({ svg, ...rest }: PpCurrencyAvatar): React.ReactElement | null {
  if (!svg) {
    return null;
  }
  return (
    <AvatarIcon {...rest}>
      <MuiSvgIcon svg={svg} />
    </AvatarIcon>
  );
}
